<template>
    <v-card class="mb-3 mt-3">
        <v-card-title
            >{{ $t("applications.searching-applications") }} ...</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="text-center">
            <v-progress-circular
                :rotate="360"
                :size="200"
                :width="15"
                :value="progress_value"
                color="primary"
                >{{ progress_value }} %</v-progress-circular
            >
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "applicant-type-search",
    props: {
        applicants: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            interval: {},
            progress_value: 0,
        };
    },
    mounted() {
        this.progress();
    },
    methods: {
        checkApplicationStatus() {
            console.log(this.applicants);
            this.$emit(
                "complete",
                this.applicants.filter(
                    (applicant) => applicant.status === "Paid"
                ).length > 0
            );
        },
        progress() {
            this.interval = setInterval(() => {
                if (this.progress_value === 100) {
                    clearInterval(this.interval);
                    this.progress_value = 0;
                    this.checkApplicationStatus();
                }
                this.progress_value += 10;
            }, this.$config("applications.nonApplicantSettings.delay"));
        },
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
};
</script>

<style scoped>
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-3 mt-3" },
    [
      _c("v-card-title", [
        _vm._v(_vm._s(_vm.$t("applications.searching-applications")) + " ...")
      ]),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticClass: "text-center" },
        [
          _c(
            "v-progress-circular",
            {
              attrs: {
                rotate: 360,
                size: 200,
                width: 15,
                value: _vm.progress_value,
                color: "primary"
              }
            },
            [_vm._v(_vm._s(_vm.progress_value) + " %")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }